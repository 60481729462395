import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { ErrorType, FetchResponse, Lookup, Meta, SerializedError } from '../@types'
import api from '../services/axios'
import { GET_NEW_OCCUPATION, GET_WORKERS, SEND_CORRECT_OCCUPATION_REQUEST } from '../constants/api'
import i18n from 'i18next'
import { serializer, IFilter, TableSort } from '@takamol/unified-components'

export const defaultWorkersFilters = `
&filters[members.laborer_status_code][eq][]=1
&filters[members.laborer_status_code][eq][]=2
&filters[members.laborer_status_code][eq][]=9
`

export type WorkPermit = {
  number: string
  fees: string
  extraFees: string
  totalFees: string
  transactionStatusCode: number
  transactionStatusName: string
  transactionNumber: string
  typeCode: number
  typeName: string
  startDate: string
  endDate: string
  transactionDate: string
  status: string
}

export type Worker = {
  id: string
  mhrsdEstablishmentId: number
  establishmentName: string
  establishmentNumber: number | null
  laborOfficeId: number
  sequenceNumber: number
  unifiedNumberId: number
  pkLaborerId: number
  personalNumber: string
  names: string
  isRegular: boolean
  gender: string
  hijriDateOfBirth: string
  nationalityCode: number
  nationalityName: string
  educationalStatusCode: string
  educationalStatusName: string
  qualificationCode: string
  qualificationName: string
  jobCode: number
  jobName: string
  laborerStatusCode: string
  laborerStatusName: string
  accommodationStatusCode: string
  accommodationStatusName: string
  entryDate: string
  religionId: number
  idReleaseDate: {}
  lastWpExpirationDate: string
  serviceStartDate: {}
  serviceEndDate: string
  passportNo: string
  passportSource: string
  passportReleaseDate: string
  borderNo: string
  workPermitStatus: string
  workPermitStartDate: {}
  workPermitEndDate: {}
  workPermit: WorkPermit
  nationality: Lookup
  occupation: Lookup
  newOccupation: Lookup
  isRequested?: boolean
}

type OccupationItem = {
  loading: boolean
  items: Lookup[]
  currentPage: number
  pageCount: number
}

export const initialOccupationItem: OccupationItem = {
  loading: true,
  items: [],
  currentPage: 1,
  pageCount: 1
}

type OccupationsList = Record<string, OccupationItem>

type CorrectOccupationRequestState = {
  loading: boolean
  loadingMoreOccupation: boolean
  error: SerializedError
  pageCount: number
  currentPage: number
  pageSize: number
  filters: IFilter
  workers: Worker[]
  occupationsList: OccupationsList
  showFilters: boolean,
  searchOccupation: string
  sortModel: TableSort
  showPageLoader: boolean
}

const stateAdapter = createEntityAdapter()

export const initialFilters: IFilter = {
  'members.personalNumber': {
    value: '',
    action: 'like',
  },
  'members.names': {
    value: '',
    action: 'like',
  },
  'occupation.title': {
    value: '',
    action: 'like',
  }
}

export const initialState = stateAdapter.getInitialState<CorrectOccupationRequestState>({
  error: {},
  currentPage: 1,
  pageCount: 1,
  pageSize: 1,
  loading: false,
  loadingMoreOccupation: false,
  filters: initialFilters,
  showFilters: false,
  workers: [],
  occupationsList: {},
  searchOccupation: '',
  sortModel: [],
  showPageLoader: false
})

type FetchWorkersParams = {
  currentPage: number
  filters: string
  sort: string
}

export const fetchWorkers = createAsyncThunk<FetchResponse<Worker[], Meta>, FetchWorkersParams, ErrorType>(
  'correctOccupationRequest/fetchWorkers',
  ({ currentPage, sort, filters }, { rejectWithValue }) => {
    return api('get', GET_WORKERS(currentPage, sort, filters + defaultWorkersFilters), {}, rejectWithValue)
  }
)

type SubmitCorrectOccupationResponse = {
  id: string
  workerPersonalNumber: string
  workerName: string
  creatorPersonalNumber: string
  establishmentNumber: string
  status: string
}

export type SubmitCorrectOccupationRequestParams = {
  workerPersonalNumber: string
  workerName: string
  newOccupationCode: number
  oldOccupationCode: number
}

export const submitCorrectOccupationRequest = createAsyncThunk<FetchResponse<SubmitCorrectOccupationResponse>,
  SubmitCorrectOccupationRequestParams,
  ErrorType>
  (
    'correctOccupationRequest/submitCorrectOccupationRequest',
    ({ workerPersonalNumber, workerName, newOccupationCode, oldOccupationCode }, { rejectWithValue }) => {
      const payload = serializer('correctOccupation', {
        workerPersonalNumber,
        workerName,
        newOccupationCode,
        oldOccupationCode
      })
      return api('post', SEND_CORRECT_OCCUPATION_REQUEST, payload, rejectWithValue)
    }
  )

type GetOccupationParams = {
  occupationCode: number | string
  currentPage?: number
  filters?: string
}

export const fetchNewOccupation = createAsyncThunk<FetchResponse<Lookup[], Meta>, GetOccupationParams, ErrorType>(
  'correctOccupationRequest/fetchNewOccupation',
  ({ occupationCode, currentPage = 1, filters = '' }, { rejectWithValue }) => {
    return api('get', GET_NEW_OCCUPATION(occupationCode, currentPage, filters, i18n.language), {}, rejectWithValue)
  }
)

export const loadMoreOccupation = createAsyncThunk<FetchResponse<Lookup[], Meta>, GetOccupationParams, ErrorType>(
  'correctOccupationRequest/loadMoreOccupation',
  ({ occupationCode, currentPage = 1, filters = '' }, { rejectWithValue }) => {
    return api('get', GET_NEW_OCCUPATION(occupationCode, currentPage, filters, i18n.language), {}, rejectWithValue)
  }
)

const correctOccupationRequest = createSlice({
  name: 'correctOccupationRequest',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setLoadingMoreOccupation(state, action: PayloadAction<boolean>) {
      state.loadingMoreOccupation = action.payload
    },
    setFilters(state, action: PayloadAction<IFilter>) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setOccupationList(state, action: PayloadAction<OccupationsList>) {
      state.occupationsList = action.payload
    },
    setWorkers(state, action: PayloadAction<Worker[]>) {
      state.workers = action.payload
    },
    setSearchOccupation(state, action: PayloadAction<string>) {
      state.searchOccupation = action.payload
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload
    },
    setSortModel(state, action: PayloadAction<TableSort>) {
      state.currentPage = 1
      state.sortModel = action.payload
    },
    setShowPageLoader(state, action: PayloadAction<boolean>) {
      state.showPageLoader = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWorkers.fulfilled, (state, action) => {
        state.loading = false
        state.error = initialState.error
        state.workers = action.payload.data
        state.pageCount = action.payload.meta.page_count
      })
      .addCase(fetchWorkers.rejected, (state, action) => {
        state.loading = false
        state.error = { ...action.payload?.errors?.[0] }
        state.workers = []
      })
      .addCase(fetchNewOccupation.fulfilled, (state, action) => {
        const { data, meta } = action.payload
        state.occupationsList = {
          [action.meta.arg.occupationCode]: {
            loading: false,
            pageCount: meta.page_count,
            currentPage: meta.current_page,
            items: data,
          }
        }
      })
      .addCase(fetchNewOccupation.rejected, (state, action) => {
        state.occupationsList = {
          [action.meta.arg.occupationCode]: {
            ...initialOccupationItem,
            loading: false
          }
        }
      })
      .addCase(loadMoreOccupation.fulfilled, (state, action) => {
        const { meta: { arg: { occupationCode } }, payload: { data, meta } } = action
        state.occupationsList = {
          [occupationCode]: {
            loading: false,
            pageCount: meta.page_count,
            currentPage: meta.current_page,
            items: [ ...state.occupationsList[occupationCode].items, ...data ],
          }
        }
      })
      .addCase(loadMoreOccupation.rejected, (state, action) => {
        state.occupationsList = {
          [action.meta.arg.occupationCode]: {
            ...state.occupationsList[action.meta.arg.occupationCode],
            loading: false,
          }
        }
      })
  }
});

export const reducer: Reducer<typeof initialState> = correctOccupationRequest.reducer;
export const {
  setLoading,
  setLoadingMoreOccupation,
  setCurrentPage,
  setFilters,
  setOccupationList,
  setWorkers,
  setSearchOccupation,
  setShowFilters,
  setSortModel,
  setShowPageLoader
} = correctOccupationRequest.actions;
